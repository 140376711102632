.shadow-sm input {
    width: auto;
}

.form-check-input:checked {
    background-color: #F79320 !important;
    border: #F79320;
}

.btn-gray-secondary {
    border: 1px solid #707272 !important;
    color: #707272 !important;
    background-color: white !important;
  }

.react-datepicker-wrapper {
    width: 100%;
}

.date-icon {
    position: absolute;
    margin-left: -30px;
    margin-top: 10px;
    color: #6c757d!important;
}
