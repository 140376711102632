.hide {
    display: none;
}

.show {
    display: '';
}

.showMsg {
    display: '';
    color: red;
}

#rebate_form_searchString {
    width: 600px;
}
/*.ant-table-row {
    & + .ant-table-expanded-row {
        &:hover {
            color:blue;
        }
    }
}*/
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active, .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: #F3A859;
  border-color: #F3A859;
}

.ant-radio-button-wrapper:hover {
  color: #F3A859;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #F3A859;
  -webkit-box-shadow: -1px 0 0 0 #F3A859;
  box-shadow: -1px 0 0 0 #F3A859;
}

.btn-orange-secondary, .btn-orange-secondary:focus {
  border: 1px solid #F3A859;
  background-color: white;
  color: #F3A859;
}


.btn-orange-secondary:hover {
  background-color: #F3A859 !important;
  color: white !important;
}

.btn-gray-secondary {
  border: 1px solid #707272 !important;
  color: #707272 !important;
  background-color: white !important;
}

.btn-gray-secondary:hover {
  border: 1px solid #707272 !important;
  color: #707272 !important;
  background-color: #f5f5f5 !important;
}

.btn-approve, .btn-approve:active, .btn-approve:focus {
  border: 1px solid #badbcc !important;
  background-color: #d1e7dd !important;
  color: #0f5132 !important;
}

.btn-approve:hover {
  border: 1px solid #badbcc !important;
  background-color: #badbcc !important;
  color: #0f5132 !important;
}

.btn-decline, .btn-decline:active, .btn-decline:focus {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.btn-decline:hover {
  color: #842029;
  background-color: #f5c2c7;
  border-color: #f5c2c7;
}

.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #f8f9fa;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    white-space: nowrap;
  }
  
  #customers tr:nth-child(even){background-color: #f8f9fa;}
  
  #customers tr:hover {background-color: #f8f9fa !important;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #707272;
    color: white;
}

.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }
  
  .certain-search-item-count {
    position: absolute;
    color: #999;
    right: 16px;
  }
  
  .certain-category-search.ant-select-focused .certain-category-icon {
    color: #108ee9;
  }
  
  .certain-category-icon {
    color: #6e6e6e;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
  }

  .global-search-wrapper {
    padding-right: 50px;
  }
  
  .global-search {
    width: 100%;
  }
  
  .global-search.ant-select-auto-complete .ant-select-selection--single {
    margin-right: -46px;
  }
  
  .global-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 62px;
  }
  
  .global-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .global-search-item {
    display: flex;
  }
  
  .global-search-item-desc {
    flex: auto;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .global-search-item-count {
    flex: none;
  }

  .ant-form-inline .ant-form-item {
      margin-top: -5px;
      margin-left: 5px;
  }

  .ant-btn-primary {
      margin-left: 5px;
  }

  .ant-table-wrapper .ant-table .ant-table-content {
      height: 500px;
  }
  .input-disabled{
    background-color:#ffffff !important;
    color: black !important;
    font-weight: normal;
    font-size:medium;
  }
  #scrollable {width: 800px;overflow-x:auto;}