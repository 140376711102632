
.solarcard {
    background-color: hsla(0,0%,100%,0.80);
}

.solarcard-dark {
    background-color: hsla(0,0%,20%,0.70);
    color: hsla(0,0%,100%,1.00);
}

.help-block { font-size: 12px; color: red;}