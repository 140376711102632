.ant-collapse-header {
    font-weight: 600;
}

.ant-collapse-header:hover {
    background-color: #F0F0F0;
}

.ant-collapse-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-color: #F0F0F0;
}

.ant-collapse-content-box p {
    padding-top: 10px;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}